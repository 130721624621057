<template>
    <teleport to="#teleport-target">
    <div class="shuiyinbox">
        <canvas ref="shuiyinCanvas" id="shuiyinCanvas"></canvas>
    </div>
    </teleport>
  </template>
  
<script setup>
   import { defineProps, ref, computed,onMounted,watch } from 'vue'
   import {useStore} from 'vuex'
   const store = useStore();
   const user = computed(() => store.state.user);
   console.log("user.value",user.value)

   const size = ref({ width: window.innerWidth, height: window.innerHeight })
   const isMobile = /iPhone|iPad|iPod|Android|micromessenger/i.test(navigator.userAgent);
     
  

   onMounted(()=>{
      const shuiyinCanvas = document.getElementById("shuiyinCanvas");
      console.log('shuiyinCanvas',shuiyinCanvas)
      const ctx = shuiyinCanvas.getContext('2d');
      init(shuiyinCanvas,ctx);

      const handleResize = () => {
         size.value = { width: window.innerWidth, height: window.innerHeight }
         console.log("size.value",size.value)
         
         init(shuiyinCanvas,ctx);
      };
      window.addEventListener("resize", handleResize);
   })

   const init = (canvas,ctx)=>{
      if(isMobile){
         canvas.width = window.innerWidth*3.2;
         canvas.height = window.innerHeight*3.2;
      }else{
         canvas.width = window.innerWidth;
         canvas.height = window.innerHeight;
      }
      //清除画布
      ctx.clearRect(0, 0, 160, 100);

      ctx.rotate(-20 * Math.PI / 180);
      ctx.globalAlpha = 0.2;
      
      ctx.fillStyle = "#999";
      ctx.textAlign = 'left';
      ctx.textBaseline = 'Middle';

      if(isMobile){
         ctx.font = "11px Microsoft JhengHei";
         for (let y = -500; y < canvas.height*2; y += 70) { // 控制每次绘制的间距
            for (let x = -500; x < canvas.width*2; x += 140) { // 控制每次绘制的列数
               ctx.fillText(user.value.name+" "+user.value.mobile, x, y); // 绘制文本 "Hello World"
            }
         }
         
      }else{
         ctx.font = "14px Microsoft JhengHei";
         for (let y = -500; y < canvas.height*2; y += 100) { // 控制每次绘制的间距
            for (let x = -500; x < canvas.width*2; x += 200) { // 控制每次绘制的列数
               ctx.fillText(user.value.name+" "+user.value.mobile, x, y); // 绘制文本 "Hello World"
            }
         }
         
      }
   }

</script>
  
<style>
.shuiyinbox,#shuiyinCanvas{
   position: fixed; z-index:3000; top:0; bottom:0; right:0; left:0; pointer-events: none;
}
</style>
  